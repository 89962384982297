import { sheets_v4 } from "googleapis";
import { usePBSSheetData } from "./hooks";
import { formatNumber, renderImage } from "../utils";
import { Fragment, useMemo, useRef } from "react";
import Frame from "./Frame";

type Widget = "all" | "break" | "break-table" | "offense" | "defense";

const playerProps = () => ({
  BREAK: {
    value: undefined,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`} className="text-yellow">
        {elem.value}
      </td>
    ),
  },
  // Breaks: {
  //   value: 0,
  //   render: (key: string, idx: number, elem: any) => <td key={`${key}${idx}`}>{elem.value}</td>,
  // },
  Success: {
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  Pocketed: {
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  Foul: {
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
});

const Stats = ({ widget }: { widget?: Widget }) => {
  const { numOfSets, rows } = usePBSSheetData();
  const viewData = {
    name: undefined,
    players: [],
  };
  // build up data structure
  if (!rows?.[0]) {
    return null;
  }
  const middleColumnIdx = (rows[0].length - 1) / 2;
  const matchName = rows?.[0]?.[middleColumnIdx]?.formattedValue;
  const player1Name = rows?.[1]?.[0]?.formattedValue;
  const player2Name = rows?.[1]?.[numOfSets + 2]?.formattedValue;
  if (player1Name && player2Name && matchName) {
    (viewData as any).name ??= matchName;
    (viewData as any).players[0] ??= playerProps();
    (viewData as any).players[1] ??= playerProps();
    (viewData as any).players[0].BREAK.value = player1Name;
    (viewData as any).players[1].BREAK.value = player2Name;
    (viewData as any).players[0].Success.value = rows?.[9]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1].Success.value = rows?.[9]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[0].Pocketed.value = rows?.[11]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1].Pocketed.value = rows?.[11]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[0].Foul.value = rows?.[10]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1].Foul.value = rows?.[10]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
  }

  const view = (
    <Frame>
      <table style={{ maxWidth: '775px', tableLayout: 'fixed', }}>
        <tbody>
          <tr className="bg-grey">
            {Object.keys(playerProps()).map((val, idx) => {
              return <td style={idx === 0 ? { width: '50%', maxWidth: '350px' } : {}} key={`playerProps${idx}`}>{val}</td>;
            })}
          </tr>
          {viewData.players.map((data: any, idx) => (
            <tr key={`playerData${idx}`} className="bg-black">
              {Object.keys(data).map((key: string, idx: number) =>
                data[key].render(key, idx, data[key]),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Frame>
  );
  return view;
};

export default Stats;
