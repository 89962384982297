import { sheets_v4 } from "googleapis";
import { usePBSSheetData } from "./hooks";
import { formatNumber, renderImage } from "../utils";
import { Fragment, useMemo, useRef } from "react";
import Frame from "./Frame";

const playerProps = () => ({
  "Balls Pocketed": {
    header: "Balls Pocketed",
    value: undefined,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`} className="text-yellow">
        {elem.value}
      </td>
    ),
  },
  first: {
    header: 1,
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  second: {
    header: 2,
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  third: {
    header: 3,
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  fourth: {
    header: 4,
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  fifth: {
    header: 5,
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
});

const BallsPocketedTable = () => {
  const { numOfSets, rows } = usePBSSheetData();
  const viewData = {
    name: undefined,
    players: [],
  };
  const ballsPocketedData = rows.find((rowData) =>
    rowData.find((elem) => elem.formattedValue === "Balls Pocketed"),
  );
  if (!ballsPocketedData) {
    return null;
  }
  const columnHeaders = rows[4].reduce((carry, elem, idx) => {
    return carry;
  }, {});
  // build up data structure
  const player1Name = rows?.[1]?.[0]?.formattedValue;
  const player2Name = rows?.[1]?.[numOfSets + 2]?.formattedValue;
  let dataColumnCount = 0;
  if (ballsPocketedData && player1Name && player2Name) {
    // subtract 1 for the middle column,
    // divide by 2 cause there is 2 players
    // @ts-ignore
    const middleColumnIdx = (ballsPocketedData.length - 1) / 2;
    dataColumnCount = (ballsPocketedData.length - 1) / 2;
    const matchName = rows?.[0]?.[middleColumnIdx]?.formattedValue;
    (viewData as any).name ??= matchName;
    (viewData as any).players[0] ??= playerProps();
    (viewData as any).players[1] ??= playerProps();
    (viewData as any).players[0]["Balls Pocketed"].value = player1Name;
    (viewData as any).players[1]["Balls Pocketed"].value = player2Name;
    (viewData as any).players[0]["first"].value =
      ballsPocketedData?.[dataColumnCount - 1]?.formattedValue;
    (viewData as any).players[1]["first"].value =
      ballsPocketedData?.[dataColumnCount + 1]?.formattedValue;

    if (
      dataColumnCount > 2 &&
      (ballsPocketedData?.[dataColumnCount - 2]?.formattedValue !== undefined ||
        ballsPocketedData?.[dataColumnCount + 2]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["second"].value =
        ballsPocketedData?.[dataColumnCount - 2]?.formattedValue;
      (viewData as any).players[1]["second"].value =
        ballsPocketedData?.[dataColumnCount + 2]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["second"];
      delete (viewData as any).players[1]["second"];
    }

    if (
      dataColumnCount > 3 &&
      (ballsPocketedData?.[dataColumnCount - 3]?.formattedValue !== undefined ||
        ballsPocketedData?.[dataColumnCount + 3]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["third"].value =
        ballsPocketedData?.[dataColumnCount - 3]?.formattedValue;
      (viewData as any).players[1]["third"].value =
        ballsPocketedData?.[dataColumnCount + 3]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["third"];
      delete (viewData as any).players[1]["third"];
    }

    if (
      dataColumnCount > 4 &&
      (ballsPocketedData?.[dataColumnCount - 4]?.formattedValue !== undefined ||
        ballsPocketedData?.[dataColumnCount + 4]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["fourth"].value =
        ballsPocketedData?.[dataColumnCount - 4]?.formattedValue;
      (viewData as any).players[1]["fourth"].value =
        ballsPocketedData?.[dataColumnCount + 4]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["fourth"];
      delete (viewData as any).players[1]["fourth"];
    }

    if (
      dataColumnCount > 5 &&
      (ballsPocketedData?.[dataColumnCount - 5]?.formattedValue !== undefined ||
        ballsPocketedData?.[dataColumnCount + 5]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["fifth"].value =
        ballsPocketedData?.[dataColumnCount - 5]?.formattedValue;
      (viewData as any).players[1]["fifth"].value =
        ballsPocketedData?.[dataColumnCount + 5]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["fifth"];
      delete (viewData as any).players[1]["fifth"];
    }

  }

  const view = (
    <Frame>
      <table style={{ maxWidth: '775px', }}>
        <tbody>
          <tr className="bg-grey">
            {Object.keys(viewData.players[0]).map((val, idx) => {
              return (
                <td style={idx === 0 ? { width: '50%', maxWidth: '350px' } : {}} key={`playerProps${idx}`}>
                  {(playerProps() as any)[val].header}
                </td>
              );
            })}
          </tr>
          {viewData.players.map((data: any, idx) => (
            <tr key={`playerData${idx}`} className="bg-black">
              {Object.keys(data).map((key: string, idx: number) =>
                data[key].render(key, idx, data[key]),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Frame>
  );
  return view;
};

export default BallsPocketedTable;
